import type { ReactElement } from 'react';
import type { IconProps } from './index';
import { Svg } from './styles';
import { colors } from '../styles';

const SvgComponent = ({ color = colors.BLACK, hoverColor, className }: IconProps): ReactElement => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={24}
    height={24}
    $hoverColor={hoverColor}
    className={className}
  >
    <path
      fill={color}
      d="m13.854 8.854-4.5 4.5a.5.5 0 1 1-.708-.707L12.293 9H2.5a.5.5 0 1 1 0-1h9.793L8.646 4.354a.5.5 0 1 1 .708-.707l4.5 4.5a.502.502 0 0 1 0 .707Z"
    />
  </Svg>
);

export default SvgComponent;
